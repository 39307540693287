import React, { useEffect } from 'react';
import { CommonBackContent } from '../Layouts/CommonBackContent';
import { useConfigProvider } from '@/context/ConfigProvider';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
// import { Affix, Button, Modal } from 'antd';
// import Image from 'next/image';
import { useTranslation } from 'next-i18next';
// import { CloseOutlined } from '@ant-design/icons';
import { useToggle } from 'react-use';
import { PageType } from '@/shared/app-common';

const LoginStateInfo = dynamic(() => import('./LoginStateInfo'), { ssr: true });
const DetailShowContent = dynamic(() => import('./DetailShowContent'), { ssr: true });
const FeaturesContent = dynamic(() => import('./FeaturesContent'), { ssr: true });
const UseShowContent = dynamic(() => import('./UseShowContent'), { ssr: true });
const CustomersTalkUsContent = dynamic(() => import('./CustomersTalkUsContent'), { ssr: true });
const RecommendedArticleContent = dynamic(() => import('./RecommendedArticleContent'), {
  ssr: true,
});
const RegisterContent = dynamic(() => import('./RegisterContent'), { ssr: true });
// const HomeAIContent = dynamic(() => import('./HomeAIContent'), { ssr: true });
const LandingPageIntroduce = dynamic(() => import('./LandingPageIntroduce'), { ssr: true });

const MemoCommonBackContent = React.memo(CommonBackContent);

const HomeContent = ({ data }: { data: any }) => {
  const { isBlogWeb } = useConfigProvider();
  const router = useRouter();
  const { t } = useTranslation('common');
  // const [showNewFeature, setShowNewFeature] = useToggle(true);
  // const [open, setOpen] = useToggle(false);
  const pageType = data.pageType;
  useEffect(() => {
    if (isBlogWeb) {
      router.push('/instagram-tracking-blog');
    }
  }, []);

  // const rightAlertContainer = useMemo(() => {
  //   return (
  //     <div
  //       style={{
  //         float: 'right',
  //         marginRight: '20px',
  //         textAlign: 'center',
  //         cursor: 'pointer',
  //         display: showNewFeature ? 'block' : 'none',
  //       }}
  //       onClick={() => {
  //         setOpen(true);
  //       }}
  //     >
  //       <CloseOutlined
  //         style={{
  //           background: '#FFF',
  //           float: 'right',
  //           padding: '5px',
  //           borderRadius: '50%',
  //         }}
  //         onClick={(e) => {
  //           e?.stopPropagation();
  //           setShowNewFeature(false);
  //         }}
  //       />
  //       <Image src={'/images/home/home_new_icon.webp'} width={50} height={50} alt={'new_icon'} />
  //       <div
  //         style={{
  //           fontWeight: '500',
  //           textShadow: '1px 1px 0 white, -1px -1px 0 white, -1px 1px 0 white, 1px -1px 0 white',
  //         }}
  //       >
  //         {t('New Feature')}
  //       </div>
  //     </div>
  //   );
  // }, [showNewFeature, setOpen, setShowNewFeature]);

  // const modalContent = useMemo(() => {
  //   return (
  //     <Modal
  //       className={'.commonModalContainer'}
  //       closeIcon={<CloseOutlined style={{ color: '#FFF' }} />}
  //       open={open}
  //       onCancel={() => setOpen(false)}
  //       destroyOnClose={true}
  //       footer={null}
  //     >
  //       <div
  //         style={{
  //           color: '#FFF',
  //           padding: '20px 24px',
  //           backgroundImage: `url('/images/home/home-new-feature-bg.webp')`,
  //           borderRadius: '8px',
  //         }}
  //       >
  //         <div
  //           style={{
  //             fontSize: '20px',
  //             fontWeight: 'bold',
  //             marginBottom: '18px',
  //           }}
  //         >
  //           {t('New Feature Alert!')}
  //         </div>
  //         <p
  //           style={{
  //             marginBottom: '18px',
  //           }}
  //         >
  //           {t(
  //             'Exciting news! DolphinRadar now offers AI Insights, providing you with deeper, more actionable understanding of the accounts you track.',
  //           )}
  //         </p>
  //         <Button
  //           style={{
  //             height: '40px',
  //             borderRadius: '20px',
  //             color: 'white',
  //             background: `linear-gradient( 180deg, #725CFF 0%, #8D83FF 100%)`,
  //             border: 'none',
  //             cursor: 'pointer',
  //             fontSize: '14px',
  //           }}
  //           onClick={() => {
  //             router.push('/analytics/1705045472413474315?name=kyliejenner&isDemo=true&dataType=1');
  //           }}
  //         >
  //           {t('Explore AI Insights Now')}
  //         </Button>
  //       </div>
  //     </Modal>
  //   );
  // }, [open]);

  return (
    <>
      {isBlogWeb ? (
        <></>
      ) : (
        <div>
          <MemoCommonBackContent>
            <LoginStateInfo pageType={pageType} />
            {pageType === PageType.Normal && <DetailShowContent />}
            {/* <HomeAIContent /> */}
            {pageType !== PageType.Normal && <LandingPageIntroduce pageType={pageType} />}
            <FeaturesContent />
            <UseShowContent />
          </MemoCommonBackContent>

          {data?.articleList?.length > 0 && <RecommendedArticleContent dataList={data?.articleList} />}
          <CustomersTalkUsContent />

          <MemoCommonBackContent>
            <RegisterContent />
          </MemoCommonBackContent>

          {/* <Affix offsetBottom={220}>{rightAlertContainer}</Affix> */}

          {/* {modalContent} */}
        </div>
      )}
    </>
  );
};

export default HomeContent;
